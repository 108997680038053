<template>
  <nav class="bg-white border-b border-gray-300 dark:bg-gray-900">
    <div class="max-w-screen-xl flex items-center justify-between mx-auto">
      <div class="flex items-center ml-2 sm:ml-0">
        <!-- Mobile Logo (visible on screens smaller than 640px) -->
        <NuxtLink
          to="/"
          class="flex items-center space-x-3 rtl:space-x-reverse sm:hidden"
        >
          <img
            src="/assets/images/minilogo.svg"
            alt="Logo"
            class="sm:h-8 h-6"
          />
        </NuxtLink>

        <!-- Desktop Logo (visible on screens 640px and above) -->
        <NuxtLink to="/" class="hidden sm:flex items-center md:space-x-1">
          <img src="/assets/images/logo.svg" alt="Logo" class="sm:h-8 h-6" />
        </NuxtLink>

        <!-- Tabs with space-x-10 -->
        <ul class="flex md:space-x-5 sm:space-x-2 text-gray-500 ml-2 md:ml-8">
          <li>
            <NuxtLink
              to="/"
              :class="[
                'block text-xs sm:text-sm font-semibold py-3 px-1 hover:text-primary-600 hover:border-b-2 hover:border-primary-600',
                {
                  'text-primary-600 border-b-2 border-primary-600':
                    $route.path === '/',
                },
              ]"
            >
              Patient Dashboard
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              to="/payer-insights"
              :class="[
                'block text-xs sm:text-sm font-semibold py-3 px-1 hover:text-primary-600 hover:border-b-2 hover:border-primary-600',
                {
                  'text-primary-600 border-b-2 border-primary-600':
                    $route.path === '/payer-insights',
                },
              ]"
            >
              Payer Insights
            </NuxtLink>
          </li>
        </ul>
      </div>

      <!-- UserProfile Component -->
      <div class="mr-3 sm:mr-0">
        <UserProfile />
      </div>
    </div>
  </nav>
</template>
